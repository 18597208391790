<template>
  <div>

    <!-- 在校本资源那里有用这个模板，后期如果修改这里  对应的需要去修改校本资源 -->
    <el-form :model="form"
             :rules="rules"
             ref="formRef"
             label-width="100px">
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="试卷名称">
            <el-input clearable
                      placeholder="请输入"
                      style="width:100%"
                      v-model="form.paper_name" />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="指定年级"
                        prop="given_garde">
            <el-select v-model="form.given_garde"
                       clearable
                       style="width:100%"
                       @change="gradeChange"
                       placeholder="选择">
              <el-option v-for="item in gradeList"
                         :key="item.grade_id"
                         :label="item.grade_name"
                         :value="item.grade_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="试卷科目"
                        prop="subjectId">
            <el-select v-model="form.subjectId"
                       style="width:100%"
                       :disabled="isResource"
                       @change="subjectChage"
                       placeholder="选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="试卷地区"
                        prop="district_id">
            <el-select v-model="form.district_id"
                       style="width:100%"
                       @change="changeDistrict"
                       :disabled="isResource"
                       filterable
                       placeholder="选择">
              <el-option v-for="item in districtList"
                         :key="item.paper_district_id"
                         :label="item.paper_district_desc"
                         :value="item.paper_district_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="试卷年份"
                        prop="question_year">
            <el-select v-model="form.question_year"
                       style="width:100%"
                       :disabled="isResource"
                       placeholder="选择"
                       @change="changeYear">
              <el-option v-for="item in year"
                         :key="item"
                         :label="item"
                         :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8"
                v-if="is_detailed==1">
          <el-form-item label="详细来源">
            <el-switch v-model="form.source_paper"
                       :active-value="1"
                       :inactive-value="0"
                       active-text="是"
                       inactive-text="否"
                       @change="changeSourceType" />
          </el-form-item>
        </el-col>
        <el-col :span="8"
                v-show="form.source_paper==0">
          <el-form-item label="试卷来源">
            <el-select v-model="form.source_id"
                       filterable
                       :disabled="fromResource"
                       style="width:100%"
                       placeholder="选择">
              <el-option v-for="item in sourceList"
                         :key="item.source_id"
                         :label="item.source_name"
                         :value="item.source_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8"
                v-show="this.form.source_paper==1">
          <el-form-item label="试卷来源">
            <el-select v-model="form.source_id"
                       :disabled="fromResource"
                       filterable
                       clearable
                       style="width:100%"
                       placeholder="选择">
              <el-option v-for="item in sourceList1"
                         :key="item.source_id"
                         :label="item.source_name"
                         :value="item.source_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="试卷学期">
            <el-select v-model="form.semester"
                       style="width:100%"
                       placeholder="选择">
              <el-option v-for="item in semesterList"
                         :key="item.id"
                         :label="item.label"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="试卷类型">
            <el-select v-model="form.edu_paper_type"
                       style="width:100%"
                       placeholder="选择">
              <el-option v-for="item in paperTypeList"
                         :key="item.paper_type_id"
                         :label="item.paper_type_name"
                         :value="item.paper_type_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="试卷时长">
            <el-input clearable
                      placeholder="请输入"
                      style="width:100%"
                      v-model="form.paper_duration" />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="开始时间">
            <el-date-picker v-model="form.paper_time"
                            style="width:100%"
                            type="datetime"
                            @focus="$forbid"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="试卷开始时间" />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="截止时间">
            <el-date-picker v-model="form.end_time"
                            style="width:100%"
                            @focus="$forbid"
                            type="datetime"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="试卷开始时间" />
          </el-form-item>
        </el-col>

      </el-row>
      <div class="tip_wrap">
        <p>
          <span style="color:#f00;padding:0 4px 0 ">*</span>说明：
        </p>
        <p>
          1.试卷可以定时开始训练，也可以不选时间，这样学生任何时间都可以训练；
        </p>
        <p>
          2.组的试卷必须提交才能导出或提供给学生做题。
        </p>
        <p>
          <!-- 3.指定年级和指定班级二选一 -->
        </p>

        <!-- <p>
          （注：试卷类型添加“模考统考”，由上到下依次为“单元检测”、“月考联考”、“期中考试”、“期
          末考试”、“模考统考”）
        </p> -->
      </div>
      <el-row :gutter="10"
              style="text-align:center;margin-top:20px">
        <el-col :span="24">
          <el-button type="primary"
                     @click="toInfo">提交</el-button>
        </el-col>
      </el-row>
    </el-form>

  </div>
</template>

<script>
import { getSourceList as getSourceListApi } from '@/api/source.js'
import { checkTime } from '@/utils/date.js'
export default {
  data () {
    return {
      form: {},
      width: '900px',
      showList: [],
      dialogVisible: false,
      testingList: [],
      subjectList: [],
      paperTypeList: [],
      gradeList: [],
      sourceList: [],
      sourceList1: [],//详细来源
      districtList: [],
      rules: {
        paper_name: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        paper_time: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        subjectId: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        district_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        source_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        question_year: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        semester: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        given_garde: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        edu_paper_type: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      year: [],
      semesterList: [
        { id: 0, label: '上学期' },
        { id: 1, label: '下学期' },
        { id: 2, label: '未指定' },
      ],
      // 选择校本资源为来源的时候
      isResource: false,
      // 从校本资源页面点击组卷的时候
      fromResource: false,
      is_detailed: 0
    }
  },
  mounted () {

    this.$http({
      url: '/api/v1/public/get_record',
      method: 'get',
      params: {
        module_id: 3
      }
    }).then(res => {
      if (res.data && res.data.list.length != 0) {
        this.is_detailed = JSON.parse(window.localStorage.getItem('userInfo')).is_detailed
        let form = res.data.list
        this.form = {
          paper_name: form.paper_name || '',
          paper_time: form.paper_time || '',
          end_time: form.end_time || '',
          source_paper: form.source_paper || 0,
          source_id: form.source_id && form.source_id < 3 || (form.source_paper == 1 && this.is_detailed == 1) ? form.source_id : '',
          question_year: form.question_year || '',
          semester: form.semester || 2,
          edu_paper_type: form.edu_paper_type || '',
          given_garde: form.given_garde || '',
          paper_duration: form.paper_duration || '',
          subjectId: form.subject_id || '',
          district_id: form.district_id || '',
        }
        if (!this.is_detailed) {
          this.form.source_paper = 0
        }
        if (this.form.end_time && !checkTime(this.form.end_time, 1)) {
          this.form.end_time = ''
        }
        this.getList()
      }
    })

  },
  watch: {
    'form.source_id': {
      handler (newVal, oldVal) {
        if (newVal > 3 && this.form.source_paper == 0) {
          this.getDetail()
        } else if (oldVal > 3 && newVal < 3 && this.form.source_paper == 0) {
          this.reset()
        }
      }
    }
  },
  methods: {
    getGradeAndClass () {
      this.$http({
        url: '/api/v1/public/subject_get_grade_and_class',
        method: 'get',
        params: {
          subject_id: this.form.subjectId
        }
      }).then(res => {
        this.gradeList = res.data.grade_data
        if (!this.form.given_garde) {
          this.form.given_garde = this.gradeList.grade_id
        }
      })
    },
    clearGradeAndClass () {
      // this.form.given_garde = ''
      this.gradeList = []
    },
    // 赋值科目id，year，地区id
    setSubjectAndYearAndDis (subject, year, dis) {
      this.form.subjectId = subject
      this.form.question_year = year
      this.form.district_id = dis
      this.getDirList()
      this.subjectList = []
    },
    reset () {
      this.form.edu_paper_type = ''
      this.paperTypeList = []
      this.isResource = false
      this.clearGradeAndClass()
      this.setSubjectAndYearAndDis(this.form.subjectId, this.form.question_year, this.form.district_id)
      this.getGradeList()
    },

    getDetail () {
      this.$http({
        url: '/api/v1/file/source_detail',
        method: 'get',
        params: {
          source_id: this.form.source_id
        }
      }).then(res => {
        let data = res.data
        this.setSubjectAndYearAndDis(data.subject_id, data.year, data.paper_district_id)
        this.clearGradeAndClass()
        this.subjectList = [
          { subject_id: data.subject_id, subject_name: data.subject_name }
        ]
        this.isResource = true
        this.getGradeAndClass()
      })
    },

    toInfo () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.subject_id = form.subjectId
        delete form.subjectId
        if (form.source_id == '') {
          form.source_paper = ''
        }
        // 传递给父组件  父组件去拿学生那边的信息
        this.$emit('validate', form)
      })
    },
    changeYear () {
      if (this.form.source_paper == 1) {
        this.getSourceList1()
      }
    },

    //是否显示详细来源
    changeSourceType () {
      if (this.form.source_paper == 1) {
        if (this.isResource) {
          this.getGradeList()
          this.isResource = false
        }
        this.getSourceList1()
        // this.reset()
      }
      if (this.form.source_paper == 0 && this.sourceList.length == 0) {
        this.getSourceList()
      }
      this.form.source_id = ''
    },
    changeDistrict () {
      // 详细来源与地区相关
      if (this.form.source_paper == 1) {
        this.getSourceList1()
      }
    },
    getDirList () {
      this.$http({
        url: '/api/v1/combination/region',
        method: 'get',
        params: {
          subject_id: this.form.subjectId,
          grade_id: this.form.grade_id
        }
      }).then(res => {
        this.districtList = res.data.districtList
        if (!this.form.district_id || this.districtList.filter(item => item.paper_district_id == this.form.district_id).length == 0) {
          this.form.district_id = this.districtList[0].paper_district_id
        }
        if (this.form.source_paper == 1) {
          this.getSourceList1()
        }
      })
    },
    getYearList () {
      this.$http({
        url: '/api/v1/public/year_all',
        method: 'get',
      }).then(res => {
        this.year = res.data
      })
    },
    addRules () {
      this.subjectList = []
      if (this.form.source_id > 3 && this.form.source_paper == 0) return
      // this.form.subjectId = ''
      this.getSubjectList()
    },
    subjectChage () {
      this.paperTypeList = []
      this.form.edu_paper_type = ''
      if (this.form.given_garde == '' && this.form.subjectId == '') return
      if (this.form.given_garde) {
        var form = {
          grade_id: this.form.given_garde,
          subject_id: this.form.subjectId
        }
      } else {
        var form = {
          special_class_subject_id: this.form.subjectId
        }
      }
      this.getDirList()
      this.getPaperTypeList(form)
    },
    gradeChange () {
      this.paperTypeList = []
      if (this.form.given_garde == '') return
      this.addRules()
      // this.subjectChage()
    },
    getSourceList () {
      this.$http({
        url: '/api/v1/public/sum_up_source',
        method: 'get',
      }).then(res => {
        this.sourceList = res.data
        this.getOther()
      })
    },
    getOther () {
      this.$http({
        url: '/api/v1/file/paper_source',
        method: 'get',
      }).then(res => {
        res.data.list.forEach(item => {
          this.sourceList.push({
            source_name: item.paper_name,
            source_id: item.source_id
          })
        });
      })
    },
    getList () {
    
      this.getGradeList()
      // this.getDirList()
      if (this.form.source_paper == 0) {
        this.getSourceList()
      } else {
        // this.getSourceList1() 
      }
      this.getYearList()
    },
    getSubjectList () {
      if (!this.form.given_garde) {
        return
      }
      this.$http({
        url: '/api/v1/public/class_grade_subject',
        method: 'get',
        params: {
          grade_id: this.form.given_garde,
        }
      }).then(res => {
        this.subjectList = res.data
        let arrList = Object.values(this.subjectList)
        if (this.form.subjectId && arrList.filter(item => item.subject_id == this.form.subjectId).length == 0) {
          this.form.subjectId = arrList[0].subject_id
        } else if (!this.form.subjectId) {
          this.form.subjectId = arrList[0].subject_id
        }
        this.subjectChage()
      })
    },
    async getSourceList1 () {
      // this.form.source_id = ''
      this.sourceList1 = []
      if (!this.form.subjectId || !this.form.district_id) return
      let params = {
        subject_id: this.form.subjectId,
        district_id: this.form.district_id,
        year: this.form.question_year,
        grade_id: this.form.given_garde
      }
      const { data } = await getSourceListApi(params)
      this.sourceList1 = data.list
      if (this.form.source_id && this.sourceList1 && this.sourceList1.length > 0) {
        if (this.sourceList1.filter(item => item.source_id == this.form.source_id).length == 0) {
          this.form.source_id = this.sourceList1[0].source_id
        }
      } else if (this.form.source_id && this.sourceList1 && this.sourceList1.length == 0) {
        this.form.source_id = ''
      } else if (!this.form.source_id) {
        // this.form.source_id = this.sourceList1[0].source_id
        this.form.source_id = ''
      }
    },
    getGradeList () {
      this.$http({
        url: '/api/v1/school/select_grade',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list
        this.form.given_garde = this.form.given_garde || this.gradeList[0].grade_id
        this.getSubjectList()
      })
    },
    getPaperTypeList (form) {
      this.$http({
        url: '/api/v1/public/paper_type',
        method: 'GET',
        params: form
      }).then(res => {
        if (res.data.paper_type_id) {
          this.paperTypeList = [res.data]
        } else {
          this.paperTypeList = Object.values(res.data)
        }
        if (this.paperTypeList.length == 1) {
          this.form.edu_paper_type = this.paperTypeList[0].paper_type_id
        }
      })
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
      this.form.source_id = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.warp {
  width: 98%;
  margin: 0 auto;
  border-top: 1px solid #ddd;
  font-size: 14px;
  div {
    margin-left: 15px;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
.tip_wrap {
  line-height: 24px;
}
</style>